import React, { Component } from "react";
import "./About.css";

class About extends Component {
  render() {
    return (
      <div className="about">
        <h1>About</h1>
        <p>
          This is my personal blog. I don&apos;t post a lot. You can contact me via{" "}
          <a href="mailto:dino.hensen@gmail.com">this address</a>.
        </p>
      </div>
    );
  }
}

export default About;
